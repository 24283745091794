<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Pedido </v-card-subtitle>

    <v-card-text >
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" lg="6">
            <SelectCliente :rules="reglas.cliente" :label="'Cliente'" @GetResult="GetCliente" v-model="datosPedido.idCliente" :clearable="true" :dense="true"/>
          </v-col>
          <v-col cols="12" lg="6">
            <SelectEmpleado :cargoEmpleado="'1'" :rules="reglas.empleado" :label="'Vendedor'" @GetResult="GetEmpleado" v-model="datosPedido.idVendedor" :clearable="true" :dense="true"/>
          </v-col>
          <v-col cols="12" lg="6">
            <SelectTipoPedido :rules="reglas.tipoPedido" @GetResult="GetTipoPedido" v-model="datosPedido.idTipoPedido" :outlined="true" :clearable="true" :dense="true"  />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              :rules="reglas.observaciones"
              v-model="datosPedido.observaciones"
              label="Observaciones"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-row class="mb-1">
      <v-col cols="12" class="text-right">
        <v-btn class="mr-3" @click="abrirModalBusquedaProductoA()" small color="primary">Agregar productos</v-btn>
      </v-col>
      <!-- <v-col>
        <BuscardorProductoBarra v-model="producto" ref="BuscardorProductoBarraRef"></BuscardorProductoBarra>
      </v-col> -->
    </v-row>
    <v-row v-if="datosPedido.detallePedido != null && datosPedido.detallePedido.length > 0">
      <v-col cols="12" lg="9">
        <v-card-text>
          <template>
            <v-simple-table fixed-header height="300px" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Producto</th>
                    <th class="text-left">Cantidad</th>
                    <th class="text-left">Precio</th>
                    <th class="text-left">SubTotal</th>
                    <th class="text-left">Descuento</th>
                    <th class="text-left">Iva</th>
                    <th class="text-left">Total</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in datosPedido.detallePedido" :key="i" >
                    <td @click="abrirModal(item)">
                      <div class="d-flex align-center">
                        <div class="d-flex flex-column">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <small v-bind="attrs" v-on="on">
                                <span
                                  v-if="item.producto.nombre.length < 30"
                                  class="d-block font-weight-semibold text--primary text-truncate"
                                >
                                  {{ FuncionesGenerales.camelCase(item.producto.nombre) }}
                                </span>
                                  
                                <span v-else class="d-block font-weight-semibold text--primary text-truncate">
                                  {{ FuncionesGenerales.camelCase(item.producto.nombre.substring(0, 27)) }}...
                                </span>
                              </small>
                            </template>
                            <span> {{ FuncionesGenerales.camelCase(item.producto.nombre) }}</span>
                          </v-tooltip>
                          <!-- <small v-if="item.idLote">
                            Lote: {{ FuncionesGenerales.camelCase(item.producto.lote[0].numeroLote) }}
                          </small> -->
                          <small> Barra: {{ FuncionesGenerales.camelCase(item.producto.barra) }} </small>
                        </div>
                      </div>
                    </td>
                    <td @click="abrirModal(item)">
                      <small class="primary--text">
                        {{ item.numeroUnidades}}
                      </small>
                    </td>
                    <td>
                      <small class="primary--text">
                        {{ monedaPais.simbolo }} {{ FuncionesGenerales.formatoNumeric(item.pvp) }}
                      </small>
                    </td>
                    <td @click="abrirModal(item)">
                      <small class="primary--text">
                        {{ monedaPrincipal.simbolo }} {{ FuncionesGenerales.formatoNumeric(item.pvp * item.numeroUnidades) }}
                      </small>
                    </td>
                    <td @click="abrirModal(item)">
                      <small class="primary--text">
                        {{ monedaPrincipal.simbolo }} {{ FuncionesGenerales.formatoNumeric(item.descuento) }}
                      </small>
                    </td>
                    <td @click="abrirModal(item)">
                      <small class="primary--text">
                        {{ monedaPrincipal.simbolo }} {{ FuncionesGenerales.formatoNumeric(item.iva) }}
                      </small>
                    </td>
                    <td @click="abrirModal(item)">
                      <small class="primary--text">
                        {{ monedaPrincipal.simbolo }} {{ FuncionesGenerales.formatoNumeric(item.total)  }}
                      </small>
                    </td>
    
                    <td class="text-center">
                      <v-btn @click="eliminarItem(item)" color="error" icon x-small>
                        <v-icon>{{ icons.mdiDelete }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <v-divider></v-divider>
        </v-card-text>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" lg="3" >
        <v-simple-table dense>
          <template>
            <tbody>
              <tr>
                <td class="text-left">
                  <h5>Descuento:</h5>
                </td>
                <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                  <h5>{{ monedaPrincipal.simbolo }}.{{ FuncionesGenerales.formatoNumeric(datosPedido.montoDescuento) }}</h5>
                </td>
              </tr>
              <tr>
                <td class="text-left">
                  <h5>IVA:</h5>
                </td>
                <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                  <h5>{{ monedaPrincipal.simbolo }}.{{ FuncionesGenerales.formatoNumeric(datosPedido.montoIva) }}</h5>
                </td>
              </tr>
              <tr>
                <td class="text-left">
                  <h5>SubTotal:</h5>
                </td>
                <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                  <h5>{{ monedaPrincipal.simbolo }}.{{ FuncionesGenerales.formatoNumeric(datosPedido.montoSubTotal) }}</h5>
                </td>
              </tr>
              <tr>
                <td class="text-left">
                  <h3>Total:</h3>
                </td>
                <td class="text-right">
                  <h3>{{ monedaPrincipal.simbolo }}.{{ FuncionesGenerales.formatoNumeric(datosPedido.montoTotal) }}</h3>
                </td>
              </tr>

              <!-- <tr>
                <td class="text-left">
                  <h5 v-if="factura.resta >= 0">Resta:</h5>
                  <h5 v-else>Vuelto</h5>
                </td>
                <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                  <h5>
                    <span :class=" factura.resta.toFixed(2) <= 0 ? 'success--text' : 'error--text'"
                      >{{ monedaPrincipal.simbolo }}.{{
                        factura.resta >= 0 ? factura.resta.toFixed(2) : (factura.resta * -1).toFixed(2)
                      }}</span
                    >
                  </h5>
                </td>
                <td class="text-right" v-if="visualizarMoneda.includes('PAIS')">
                  <h5 class="success--text">
                    {{ monedaPais.simbolo }}.{{
                      factura.restaPais >= 0 ? factura.restaPais.toFixed(2) : (factura.restaPais - 1).toFixed(2)
                    }}
                  </h5>
                </td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="text-right">
          <v-btn color="secondary" class="me-3" :disabled="guardando" @click="limpiar()" small> Limpiar</v-btn>
          <v-btn color="primary" class="me-3" :loading="guardando" small @click="Guardar()"> Guardar </v-btn>
      </v-col>
    </v-row>

    <ModalBusquedaProductoAvanzado
      @GetProductos="GetProductos"
      :columnas="['ID', 'NOMBRE', 'CODIGO', 'EXISTENCIA']"
      :fullscreen="true"
      :soloExistencia="false"
      :IndExistencia="false"
      :indLote="false"
      ref="ModalBusquedaProductoAvanzadoRef"
    >
    </ModalBusquedaProductoAvanzado>
    <modal-editar-producto
        ref="ModalEditarProductoRef"
        @modificar="addDetalle"
      ></modal-editar-producto>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import PedidoServices from '@/api/servicios/PedidoServices'
import SelectArea from '@/views/sistema/inventario/archivo/area/componentes/AreaSelect.vue'
import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
import SelectCliente from '@/views/sistema/crm/cliente/componentes/Select.vue'
import SelectEmpleado from '@/views/sistema/configuracion/empleado/componentes/Select.vue'
import BuscardorProductoBarra from '../../../producto/catalogo/producto/componentes/BuscardorProductoBarra.vue'
import ModalBusquedaProductoAvanzado from '../../../producto/catalogo/producto/componentes/ModalBusquedaProductoAvanzado.vue'
import SelectTipoPedido from '../../../maestro/tipo-pedido/componentes/Select.vue'
import { mdiDelete } from '@mdi/js'
import moment from 'moment'
import FuncionesGenerales from '@/funciones/funciones'
import ModalEditarProducto from '../../../../sistema/venta/facturacion/componentes/ModalEditarProducto.vue'
export default {

  components: {
    AddImages,
    SelectArea,
    SelectCliente,
    SelectEmpleado,
    BuscardorProductoBarra,
    ModalBusquedaProductoAvanzado,
    SelectTipoPedido,
    ModalEditarProducto
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const ModalEditarProductoRef =  ref(null)
    const ModalBusquedaProductoAvanzadoRef = ref(null)
    const AddImagesRef = ref(null)
    const monedaPais = FuncionesGenerales.monedaPais()
    const monedaPrincipal = FuncionesGenerales.monedaPrincipal()
    const visualizarMoneda = ['PRINCIPAL'] //["PRINCIPAL","PAIS"]
    const reglas = {
      cliente: [
        v => !!v || "El cliente es requerido"
      ],
      empleado: [
        v => !!v || "El empleado es requerido"
      ],
      tipoPedido:[
      v => !!v || "Tipo de pedido es requerido"
      ],
      observaciones:[
      v => !!v || "Observaciones es requerido"
      ]
    }
    const datoInit = {
      id: -1 /*long*/,
      idTipoPedido: null /*int*/,
      idArea: -1 /*int*/,
      idEstatus: -1 /*int*/,
      idCliente: null /*int*/,
      idVendedor: null/*int*/,
      numeroDocumento: '' /*string*/,
      cantidadProductos: -1 /*int*/,
      observaciones: '' /*string*/,
      montoSubTotal: 0 /*decimal*/,
      montoIva: 0 /*decimal*/,
      montoDescuento: 0 /*decimal*/,
      montoIgtf: 0 /*decimal*/,
      montoTotal: 0 /*decimal*/,
      montoPagado: 0 /*decimal*/,
      detallePedido: [],
      tasa: 0 /*decimal*/,
      fecha: '' /*DateTime*/,
      fechaVencimiento: '' /*DateTime*/,
      TipoPedido: {},
      cliente: {},
      vendedor: {},
    }
    const datos = ref({
      id: -1,
      descripcion: '',
      indActivo: true,
      image: null,
    })
    const sendImg = item => {
      datos.value.image = item.img[0]
    }
    const limpiar = () => {
      datosPedido.value = { ...datoInit }
      form.value.resetValidation()
      // AddImagesRef.value.imagenes = []
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datosPedido.value = item[0]
      console.log('datosPedido',datosPedido.value)
      // if (item.image) {
      //   AddImagesRef.value.imagenes = [item.image]
      // } else {
      //   AddImagesRef.value.imagenes = []
      // }

      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const abrirModal = item => {
      ModalEditarProductoRef.value.abrir({ ...item })
    }
    const datosPedido = ref(JSON.parse(JSON.stringify(datoInit)));
    const GetProductos = datosIn => {
      console.log(datosIn)
      // idLote: !element.lotes ? null : element.lotes.length == 1 ? element.lotes[0].id : null,
      datosIn.forEach(element => {
        if(datosPedido.value.detallePedido != null && !datosPedido.value.detallePedido.find(x => x.producto.id == element.producto.id)){
          datosPedido.value.detallePedido.push({
          id: element.id,
          idLote: -1,
          idProductoSucursal: element.idProductoSucursal,
          idProveedor: -1,
          idTasaImpuesto: element.producto.idTasaImpuesto,
          idProveedor: element.idProveedor,
          producto: {
            barra: element.producto.barra,
            existencia: element.existencia,
            codigoLote: '',
            id: element.producto.id,
            nombre: element.producto.nombre,
            idProducto: element.idProducto,
            idSucursal: 1,
            indActivo: true,
            costo: 0,
            factorCosto2: 0,
            costo2: 0,
            factorCosto3: 0,
            costo3: 0,
            factorVenta: 0,
            pvp: element.pvp,
            indActivoVenta: true,
            indActivoCompra: true,
            indIVA: true,
            checked: '',
            proveedor: {},
            sucursal: {},
            producto: element.producto,
            lote: !element.lotes ? [] : element.lotes.length == 1 ? [...element.lotes[0]] : [],
          },
          lotes: element.lotes,
          tasaImpuestoValor: element.producto.tasaImpuesto.valor == null ? 0 : element.producto.tasaImpuesto.valor,
          id: element.producto.id,
          idEstatus: -1,
          numeroUnidades: element.numeroUnidades == null ? 0 : element.numeroUnidades,
          pvpPais: element.pvp,
          pvp: element.pvp,
          descuento: 0.00,
          descuentoPais: 0,
          subTotal: element.pvp * 0,
          subTotalPais: element.pvp * 0,
          iva: element.producto.tasaImpuesto.valor,
          ivaPais: element.producto.tasaImpuesto.valor,
          totalPais: (element.pvp + element.producto.tasaImpuesto.valor) * 0,
          total: (element.pvp + element.producto.tasaImpuesto.valor) * 0,
        })
        console.log('OBJETO', datosPedido.value.detallePedido)
        }else{
          store.commit('setAlert', {
              message: "El producto "+ element.producto.nombre + " existe en la lista",
              type: 'info',
          })
        }
      })
    }
    
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const addDetalle = (detalle, open = true) => {
      let _existencia = 0
      if (detalle.producto.existencia.inventario > 0) {
        _existencia = detalle.producto.existencia.inventario
      } else {
        _existencia = 0
      }
      if (detalle.producto.lote.length > 0 && detalle.producto.lote[0].id != undefined) {
        if (detalle.producto.lote[0].existenciaLote.inventario > 0) {
          _existencia = detalle.producto.lote[0].existenciaLote.inventario
        } else {
          _existencia = 0
        }
      }

      if (detalle.numeroUnidades <= _existencia) {
        let tasaImpuestoValor = detalle.tasaImpuestoValor /*decimal*/
        let numeroUnidades = parseFloat(detalle.numeroUnidades) 
        let pvpPais = round(round(detalle.pvp) * FuncionesGenerales.monedaPais().tasa) /*decimal*/
        let pvp = round(detalle.pvp) /*decimal*/
        let descuento = round(0) /*decimal*/
        let descuentoPais = round(0) /*decimal*/
        let subTotal = round(numeroUnidades * pvp)
        let subTotalPais = round(numeroUnidades * pvpPais)
        let iva = round((subTotal - descuentoPais) * round(tasaImpuestoValor / 100)) /*decimal*/
        let ivaPais = round((subTotalPais - descuento) * round(tasaImpuestoValor / 100)) /*decimal*/
        let totalPais = round(subTotalPais + ivaPais - descuentoPais) /*decimal*/
        let total = round(subTotal + iva - descuento) /*decimal*/
        const detalleNew = {
          ...detalle,
          tasaImpuestoValor,
          numeroUnidades,
          pvpPais,
          pvp,
          descuento,
          descuentoPais,
          subTotal,
          subTotalPais,
          iva,
          ivaPais,
          totalPais,
          total,
        }
        const index = datosPedido.value.detallePedido.findIndex(i => i.id == detalle.id)
        datosPedido.value.detallePedido.splice(index, 1, detalleNew)
        calcularTotales();
      }else {
        store.commit('setAlert', {
          message: `la existencia disponible es de ${_existencia}`,
          type: 'error',
        })
      }
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const abrirModalBusquedaProductoA = () => {
      ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
    }

    const calcularTotales = () => {
      let cantidadProductos = 0 
      let montoSubTotal = 0
      let montoIva = 0
      let montoDescuento = 0
      // let montoIgtf = 0
      let total = 0
      datosPedido.value.detallePedido.forEach((item) => {
        cantidadProductos += item.numeroUnidades;
        montoSubTotal += item.subTotal;
        montoIva += item.iva;
        montoDescuento += item.descuento;
      })
      total += montoSubTotal + montoIva - montoDescuento
      datosPedido.value.cantidadProductos = cantidadProductos /*int*/;
      datosPedido.value.montoSubTotal = montoSubTotal/*decimal*/;
      datosPedido.value.montoIva = montoIva /*decimal*/;
      datosPedido.value.montoDescuento = montoDescuento /*decimal*/;
      // datosPedido.value.montoIgtf = 0 /*decimal*/;
      datosPedido.value.montoTotal = total /*decimal*/;
    }
    const Guardar = () => {
      datosPedido.value.idArea = 1;
      datosPedido.value.idEstatus = 22;
      datosPedido.value.fecha = moment(new Date()).local().format('YYYY-MM-DD hh:mm:ss');
      console.log('OBJECTO PEIDO',datosPedido.value)
      guardando.value = true
      if (validarForm() && datosPedido.value.montoTotal && datosPedido.value.detallePedido.length > 0) {
        try {
          PedidoServices.Actualizar(
              store.state.user.idUsuario,
              datosPedido.value
            )
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    const GetEmpleado = objetoEmpleado => {
      datosPedido.value.vendedor = objetoEmpleado;
      datosPedido.value.idVendedor = objetoEmpleado.id
    }

    const GetCliente = objetoCliente => {
      datosPedido.value.cliente = objetoCliente;
      datosPedido.value.idCliente = objetoCliente.id
    }

    const GetTipoPedido = objetoTipoPedido => {
      datosPedido.value.TipoPedido = objetoTipoPedido;
      datosPedido.value.idTipoPedido = objetoTipoPedido.id
    }

    const eliminarItem = detalle => {
      datosPedido.value.detallePedido = datosPedido.value.detallePedido.filter(i => i.id != detalle.id)
      // totalizarFactura()
    }

    return {
      CargarEditar,
      limpiar,
      Guardar,
      ActualizarLista,
      sendImg,
      GetProductos,
      abrirModalBusquedaProductoA,
      abrirModal,
      addDetalle,
      eliminarItem,
      GetEmpleado,
      GetCliente,
      GetTipoPedido,
      calcularTotales,
      ModalBusquedaProductoAvanzadoRef,
      ModalEditarProductoRef,
      icons:{
        mdiDelete
      },
      monedaPais,
      monedaPrincipal,
      reglas,
      datos,
      datosPedido,
      cargandos,
      form,
      AddImagesRef,
      FuncionesGenerales,
      visualizarMoneda,
      guardando,
    }
  },
}
</script>