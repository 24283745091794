
<template>
  <div>
    <v-card>
      <v-card-subtitle
        >Listar

        <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="Lista"
        :search="search"
        :items-per-page="5"
        :loading="cargandoDatos"
        class="table-kitchen-sink"
      >
        <template #[`item.numeroDocumento`]="{ item }">
          {{ item.numeroDocumento }}
          <span v-if="item.idEstatus == 24">
            <br />
            <small>Factura: {{ item.numeroDocumento }}</small>
          </span>
        </template>
        <template #[`item.estatus.descripcion`]="{ item }">
          <v-chip small :color="getColorEstatus(item.estatus.id)">
            {{ item.estatus.descripcion }}
          </v-chip>
        </template>
        <template #[`item.cliente.nombre`]="{ item }">
          {{ funciones.camelCase(item.cliente.nombre) }} {{ funciones.camelCase(item.cliente.apellido) }}
        </template>
        <template #[`item.vendedor.nombre`]="{ item }">
          {{ funciones.camelCase(item.vendedor.nombre) }} {{ funciones.camelCase(item.vendedor.apellido) }}
        </template>
        <template #[`item.fecha`]="{ item }">{{ funciones.formatoFecha(item.fecha) }} </template>
        <template #[`item.montoTotal`]="{ item }"> $ {{ funciones.formatoNumeric(item.montoTotal) }} </template>
        <template #[`item.indActivo`]="{ item }">
          <div class="d-flex align-center">
            <v-icon color="success" v-if="item.indActivo == true">{{ icons.mdiCheck }}</v-icon>
            <v-icon color="success" v-else>{{ icons.mdiCancel }}</v-icon>
          </div>
        </template>
        <template  #[`item.accion`]="{ item }">
          <v-tooltip v-if="item.idEstatus == 22" bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetVer(item)">
                <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
              </a>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <ModalViewPedido   ref="ModalViewRef" ></ModalViewPedido>
  </div>
</template>

<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import PedidoServices from '@/api/servicios/PedidoServices'
import funciones from '@/funciones/funciones'
import ModalViewPedido from './ModalViewPedido.vue'
export default {
  components: {
    ModalViewPedido,
  },
  setup(props, context) {
    const ModalViewRef = ref(null)
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const getColorEstatus = item => {
      switch (item.toString()) {
        case '22':
          return 'primary'
        case '23':
          return 'info'
        case '24':
          return 'success'
        case '25':
          return 'error'
        case '26':
          return 'error'
        default:
          return 'default'
          break
      }
    }
    const GetEditar = item => {
      try{  
      PedidoServices.ConsultarDetalle({
          Id: item.id,
          IdTipoPedido: -1,
          IdArea: -1,
          IdEstatus: -1,
          IdCliente: -1,
          IdVendedor: -1,
          NumeroDocumento: '',
          Fecha: null,
          FechaVencimiento: null,
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              let pedidoObjeto = response.data.datos;
              context.emit('GetEditar', { ...pedidoObjeto })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        //alert(error)
        cargandoDatos.value = false
      }
    }
    const GetVer = item => {
      //alert('GetVer')
      ModalViewRef.value.abrir(item.id)
    }
    onBeforeMount(() => {
      cargar()
    })

    const cargar = () => {
      try {
        PedidoServices.Consultar({
          Id: -1,
          IdTipoPedido: -1,
          IdArea: -1,
          IdEstatus: -1,
          IdCliente: -1,
          IdVendedor: -1,
          NumeroDocumento: '',
          Fecha: null,
          FechaVencimiento: null,
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        //alert(error)
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Numero Documento', value: 'numeroDocumento' },
        { text: 'Tipo', value: 'tipoPedido.descripcion' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Cliente', value: 'cliente.nombre' },
        { text: 'Vendedor', value: 'vendedor.nombre' },
        { text: 'Estatus', value: 'estatus.descripcion' },
        { text: 'Total', value: 'montoTotal' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      funciones,
      GetVer,
      getColorEstatus,
      ModalViewRef,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  