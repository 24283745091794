<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      label="Cargos"
      item-value="id"
      item-text="descripcion"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :append-outer-icon="add ? icons.mdiPlusCircleOutline : null"
      @click:append-outer="abrirModal()"
      :rules="rules"
      multiple
    >
    </v-autocomplete>
    
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import EmpleadoServices from '@/api/servicios/EmpleadoServices' 
export default {
  components: { 
  },
  props: {
    value: Array,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    rules: Array,
    add: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)
    const dialog = ref(false)
    const respuesta = ref({})
    

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.value != undefined) {
        select.value = props.value
      }
    }) 
    const abrirModal = () => {
      dialog.value = true
    }
    const cargarDatos = () => {
      lista.value = []
      cargando.value = true
      try {
        EmpleadoServices.ConsultarCargoEmpleado({
          Id: -1,
          Descripcion: '',
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
      }
    }
    watch(select, () => {
      contex.emit('input', select.value)
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      dialog,
      abrirModal, 
      respuesta,
    }
  },
}
</script>