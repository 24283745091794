<template>
    <row>
        <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
            <v-icon left small>
                {{ icons.mdiFilePdfBox }}
            </v-icon>
            PDF
        </v-btn>

        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" :loading="cargandoDatos"  v-on="on" @click="GetPdf()">
                    <v-icon  >{{ icons.mdiFilePdfBox }}</v-icon>
                </v-btn>
              
            </template>
            <span>PDF</span>
        </v-tooltip>
        <Pdf ref="pdfRef"></Pdf>
    </row>
</template>
<script>
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/Pdf.vue'
import PedidoServices from '@/api/servicios/PedidoServices';
export default {
    props: {
        id: Number,
        tipoBtn: {
            type: Number,
            default: 1
        }
    },
    components: {
        Pdf,
    },
    setup(props) {
        const cargandoDatos = ref(false)
        const pdfRef = ref(null)
        const GetPdf = () => {
            cargandoDatos.value = true

            PedidoServices.ConsultarDetalle({ id: props.id })
                .then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {


                        const datoInit = response.data.datos[0]
                        const cuerpoTablaDetalles = []


                        datoInit.detallePedido.forEach(item => {
                            cuerpoTablaDetalles.push(
                                [
                                    { content: item.producto.barra, styles: { halign: 'left' } },
                                    { content: item.producto.nombre, styles: { halign: 'left' } }, 
                                    { content: FuncionesGenerales.formatoNumeric(item.numeroUnidades), styles: { halign: 'right' } },
                                    { content: FuncionesGenerales.formatoNumeric(item.pvp), styles: { halign: 'right' } }, 
                                    { content: FuncionesGenerales.formatoNumeric(item.iva), styles: { halign: 'right' } },
                                    { content: FuncionesGenerales.formatoNumeric(item.total), styles: { halign: 'right' } },

                                ])
                        })


                        const datosInit = {
                            nombreDocumento: `Pedido: ${datoInit.nombreDocumento}`,

                            cabecera1: {
                                visible: true
                            },
                            titulo: {
                                visible: true,
                                posicion: "center",
                                texto: "Pedido",
                                TamanoLetras: 14,
                                color: "#7B7B7B",
                            },
                            cabecera2: {
                                visible: true,
                                datos: [
                                    { texto: "# " + datoInit.numeroDocumento, TamanoLetras: 14, color: "#FF0000" },
                                    { texto: "Cracion "    },
                                    { texto: "Fecha: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 1) },
                                    { texto: "Hora: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 2).replace(/\u00a0/g, "").replace(/\u202f/g, "") },
                                   
                                ]
                            },
                            sub1: {
                                visible: true,
                                datos: [  
                                    { texto: "Cliente "}, 
                                    { texto: "Documento: " +    datoInit.cliente.documento }, 
                                    { texto: "Nombre: " +    datoInit.cliente.nombre  +' ' + datoInit.cliente.apellido}, 
                                    { texto: "Estatus:" + datoInit.estatus.descripcion },
                                    { texto: "Tipo: " + datoInit.tipoPedido.descripcion },
                                ]
                            },
                            sub2: {
                                visible: true,
                                datos: [ 
                                    { texto: "Vendedor: " +    datoInit.vendedor.nombre  +' ' + datoInit.vendedor.apellido},
                                    { texto: "Fecha: " + FuncionesGenerales.formatoFecha(datoInit.fecha, 1) },
                                    { texto: "Vencimiento"   },
                                    { texto: "Fecha: " + FuncionesGenerales.formatoFecha(datoInit.fechaVencimiento, 1) },
                                    { texto: "Hora: " + FuncionesGenerales.formatoFecha(datoInit.fechaVencimiento, 2) }
                                ]
                            },
                            cuerpo1: {
                                visible: true,
                                datos: [
                                    {
                                        titulo: { texto: "Detalles", TamanoLetras: 12, },
                                        styles:   {fontSize: 7,},
                                        columnStyles: {

                                            3: {
                                                halign: 'right'
                                            }
                                        },
                                        tablas: {
                                        },
                                        head: [[

                                            { content: "Barra", styles: { halign: 'left' } },
                                            { content: "Producto", styles: { halign: 'left' } }, 
                                            { content: "Cant", styles: { halign: 'right' } },
                                            { content: "Precio", styles: { halign: 'right' } }, 
                                            { content: "Iva", styles: { halign: 'right' } },
                                            { content: "Total", styles: { halign: 'right' } },
                                        ]],
                                        body: cuerpoTablaDetalles,

                                    },
                                    {
                                        titulo: {},
                                        styles:   {fontSize: 7,},
                                        columnStyles: {

                                            3: {
                                                halign: 'right'
                                            }
                                        },
                                        tablas: {
                                            cant: 2,
                                            id: 2
                                        },
                                        head: [],
                                        body: [
                                            [
                                                { content: "Sub total", styles: { halign: 'left' } },
                                                { content:  FuncionesGenerales.monedaPrincipal().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.montoSubTotal) , styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Descuento", styles: { halign: 'left' } },
                                                { content:  FuncionesGenerales.monedaPrincipal().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.montoDescuento), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Impuesto", styles: { halign: 'left' } },
                                                { content: FuncionesGenerales.monedaPrincipal().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.montoIva), styles: { halign: 'right' } },
                                            ], 
                                            [
                                                { content: "Total", styles: { halign: 'left' } },
                                                { content:  FuncionesGenerales.monedaPrincipal().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.montoTotal), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Tasa " + FuncionesGenerales.monedaPais().simbolo, styles: { halign: 'left' } },
                                                { content: FuncionesGenerales.monedaPais().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.tasa), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Total "+ FuncionesGenerales.monedaPais().simbolo, styles: { halign: 'left' } },
                                                { content:  FuncionesGenerales.monedaPais().simbolo  +' ' + FuncionesGenerales.formatoNumeric(datoInit.montoTotal * datoInit.tasa), styles: { halign: 'right' } },
                                            ]
                                        ],

                                    },
                                ]
                            }
                        }




                        pdfRef.value.generarPDF(datosInit)


                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                })


        }

        return {
            GetPdf,
            cargandoDatos,
            pdfRef,
            icons: {
                mdiFilePdfBox,
            }
        }
    },
}
</script>