<template>
    <div>
        <div class="vertical-nav-header d-flex items-center pt-0 ">
            <v-img :src="dato.imagen2"
                height="60px" max-width="60px" alt="logo" contain eager class="app-logo me-3"></v-img>

        </div>
         
        <h2 class="app-title white--text">
            {{ FuncionesGenerales.camelCase(dato.razonSocial) }} </h2>
        <span class="m-0 p-0">{{ dato.documento }}</span>
        <br>
        <span class="m-0 p-0">{{
            FuncionesGenerales.camelCase(dato.direccion1)
        }}</span>
        <br>
        <span class="m-0 p-0">{{
            FuncionesGenerales.camelCase(dato.direccion2)
        }}</span>
        <br>
        <span class="m-0 p-0">{{
            FuncionesGenerales.camelCase(dato.direccion3)
        }}</span>
        <br>
        <span class="m-0 p-0">{{ dato.telefonoResponsable }}
        </span>
    </div>
</template>
<script>
import store from '@/store';
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref } from '@vue/composition-api';
import EmpresaServices from '@/api/servicios/EmpresaServices';
export default {
    setup() {

        onBeforeMount(() => {
            consultar()
        })
        const cargando = ref(false)
        const dato = ref({
            "id": 0,
            "idTipoEmpresa": 0,
            "idMoneda": 0,
            "documento": "",
            "razonSocial": "",
            "razonComercial": "",
            "registro": "",
            "documentoResponsable": "",
            "nombreResponsable": "",
            "apellidoResponsable": "",
            "telefonoResponsable": "",
            "correoResponsable": "",
            "imagen2": "",
            "indActivo": true,
             "direccion1":"",
             "direccion2":"",
             "direccion3":"",
        })

        const consultar = () => {
            cargando.value = true
            dato.value = store.state.user.empresaActual
            /*
            EmpresaServices.EmpresaConsultar({ id: store.state.user.idEmpresa })
                .then(response => {
                    if (response.data.estatus == true) {
                        console.log(response.data.datos[0])
                        dato.value = response.data.datos[0]
                    } else {
                        //alert(response.data.mensaje)
                    }
                })
                .catch(err => {
                    //alert(err)
                })
                .finally(() => {
                    cargando.value = false
                })*/
        }


        return {
            store,
            FuncionesGenerales,
            cargando,
            dato
        }
    },
}
</script>