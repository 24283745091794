<template>
  <v-card flat>
    <v-card-subtitle> Categoria </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <SelectTipoEmpleadoVue :dense="true" :outlined="true" v-model="datos.idTipoEmpleado" :add="false" />
                <v-text-field
                  v-model="datos.documento"
                  label="Documento"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                ></v-text-field>
                <v-text-field
                  v-model="datos.nombre"
                  label="Nombre"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                ></v-text-field>
                <v-text-field
                  v-model="datos.apellido"
                  label="Apellido"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                ></v-text-field>
                <SelectCargoEmpleadoVue
                  class="mt-4"
                  :dense="true"
                  :outlined="true"
                  v-model="datos.CargosEmpleado"
                  :add="false"
                />

                <v-text-field-simplemask
                  class="mt-4"
                  v-model="datos.telefono"
                  v-bind:label="'Telefono'"
                  v-bind:properties="{
                    outlined: true,
                    clearable: false,
                    dense: true,
                    rules: reglas.telefono,
                    hideDetails: 'auto',
                  }"
                  v-bind:options="{
                    inputMask: '(####) ### ## ##',
                    outputMask: '###########',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: true,
                  }"
                /> 
                <v-text-field
                  v-model="datos.correo"
                  label="Correo"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-textarea outlined dense label="Direccion" v-model="datos.direccion"></v-textarea>

                <AddImages :_width="200" :_height="180" ref="AddImagesRef" @sendImg="sendImg" :_selected="true" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import EmpleadoServices from '@/api/servicios/EmpleadoServices'

import SelectTipoEmpleadoVue from './SelectTipoEmpleado.vue'

import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
import SelectCargoEmpleadoVue from './SelectCargoEmpleado.vue'
export default {
  components: {
    SelectTipoEmpleadoVue,
    SelectCargoEmpleadoVue,
    AddImages,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const AddImagesRef = ref(null)

    const datosInit = {
      id: -1 /*int*/,
      idTipoEmpleado: 0 /*int*/,
      documento: '' /*string*/,
      nombre: '' /*string*/,
      apellido: '' /*string*/,
      direccion: '' /*string*/,
      telefono: '' /*string*/,
      img: null /*string*/,
      indActivo: true /*bool*/,
      CargosEmpleado: [],
      cargoEmpleado: [],
    }

    const reglas = {
      documento: [
        v => !!v || 'descripcion es requerido',
        v =>
          dato.value.tipoDocumento.idTipoEntidad == 1
            ? /^(\d{6,9})$/.test(v) || 'La cedula debe ser valida: ej 12365450'
            : true,
        v =>
          dato.value.tipoDocumento.idTipoEntidad == 2
            ? /^(\d{5,9}[\-]\d{1})$/.test(v) || 'El Rif debe ser valido: ej J-12345678-0'
            : true,
      ],

      requerido: [v => !!v || 'Nombre es requerido'],

      nombre: [v => !!v || 'Es requerido', v => (v && v.length <= 50 && v.length >= 3) || 'debe ser valido'],
      apellido: [v => !!v || 'Es requerido', v => (v && v.length <= 50 && v.length >= 3) || 'debe ser valido'],

      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'Participacion Invalida, Ej: (0412) 123 12 12'
            : true,
      ],
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      AddImagesRef.value.imagenes = []
      form.value.resetValidation()
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = item
      datos.value.CargosEmpleado = []
      item.cargoEmpleado.forEach(element => {
        datos.value.CargosEmpleado.push(element.id)
      })

      if (item.img) {
        AddImagesRef.value.imagenes = [item.img]
      } else {
        AddImagesRef.value.imagenes = []
      }
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          EmpleadoServices.Actualizar(store.state.user.idUsuario, datos.value)
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }
    const sendImg = item => {
      datos.value.img = item.img[0]
    }
    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      AddImagesRef,
      sendImg,
    }
  },
}
</script>